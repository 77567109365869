<template>
  <div class="merlends Factory MERCH">
    <div class="merch-card">
      <div class="mess-box">
        <div class="mess-title">基本信息</div>
        <div class="mess">
          <div>
            <div>
              <div class="mess-lable nomargin">业务姓名：</div>
              <div>{{ acticommList.name || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable nomargin">提现金额：</div>
              <div>{{ acticommList.withdrawAmount || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable nomargin">到账银行卡：</div>
              <div>{{ acticommList.bankName || "/" }}</div>
            </div>
            <div
              v-if="
                acticommList.examineStatus == 1 ||
                acticommList.examineStatus == 2 ||
                acticommList.examineStatus == 3
              "
            >
              <div class="mess-lable nomargin">审核时间:</div>
              <div>{{ acticommList.createTime || "/" }}</div>
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable nomargin">绑定账号:</div>
              <div>{{ acticommList.account || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable nomargin">申请时间：</div>
              <div>{{ acticommList.createTime || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable nomargin">到账银行卡号：</div>
              <div>{{ acticommList.bankNo || "/" }}</div>
            </div>
            <div
              v-if="
                acticommList.examineStatus == 1 ||
                acticommList.examineStatus == 2 ||
                acticommList.examineStatus == 3
              "
            >
              <div class="mess-lable nomargin">审核结果：</div>
              <div>
                {{
                  acticommList.examineStatus == 0
                    ? "待审核"
                    : acticommList.examineStatus == 1
                    ? "待打款"
                    : acticommList.examineStatus == 2
                    ? "已打款"
                    : acticommList.examineStatus == 3
                    ? "已驳回"
                    : "/"
                }}
              </div>
            </div>
          </div>

          <div>
            <div>
              <div class="mess-lable nomargin">所属团队:</div>
              <div>{{ acticommList.team || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable nomargin">手续费：</div>
              <div>{{ acticommList.withdrawCommission || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable nomargin">账户名：</div>
              <div>{{ acticommList.accountName || "/" }}</div>
            </div>
            <div v-if="acticommList.examineStatus == 3">
              <div class="mess-lable nomargin">驳回原因：</div>
              <div>{{ acticommList.rejectReason || "/" }}</div>
            </div>
            <div v-if="acticommList.examineStatus == 2">
              <div class="mess-lable nomargin">打款人：</div>
              <div>{{ acticommList.payer || "/" }}</div>
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable nomargin">手机号：</div>
              <div>
                {{ acticommList.phone || "/" }}
              </div>
            </div>
            <div>
              <div class="mess-lable nomargin">手续费扣除方式：</div>
              <div>
                {{
                  acticommList.withdrawCommissionType == 0 ? "按比例扣除" : "/"
                }}
              </div>
            </div>

            <div>
              <div class="mess-lable nomargin">开户支行：</div>
              <div>{{ acticommList.openAccountBranch || "/" }}</div>
            </div>
            <div v-if="acticommList.examineStatus == 2">
              <div class="mess-lable nomargin">打款时间：</div>
              <div>{{ formatDate(acticommList.paymentTime) || "/" }}</div>
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable nomargin">性别：</div>
              <div>
                {{
                  acticommList.sex == 0
                    ? "男"
                    : acticommList.sex == 1
                    ? "女"
                    : "/"
                }}
              </div>
            </div>
            <div>
              <div class="mess-lable nomargin">费率：</div>
              <div>{{ acticommList.withdrawRatio || "/" }}</div>
            </div>

            <div>
              <div class="mess-lable nomargin">打款金额：</div>
              <div>{{ acticommList.paymentAmount || "/" }}</div>
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable nomargin">状态：</div>
              <div>
                {{
                  acticommList.onJob == 0
                    ? "离职"
                    : acticommList.onJob == 1
                    ? "在职"
                    : "/"
                }}
              </div>
            </div>
            <div>
              <div class="mess-lable nomargin">到账银行：</div>
              <div>{{ acticommList.bankName || "/" }}</div>
            </div>
            <div
              v-if="
                acticommList.examineStatus == 1 ||
                acticommList.examineStatus == 2 ||
                acticommList.examineStatus == 3
              "
            >
              <div class="mess-lable nomargin">审核人：</div>
              <div>{{ acticommList.auditor || "/" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 列表 -->

    <div>
      <div class="mess-title title-bor">交易信息</div>
      <div class="table-box">
        <el-table
          :data="WithdrawDetaList.list"
          border
          style="width: 100%"
          height="380"
          :header-cell-style="{ background: '#F2F3F5' }"
        >
          <el-table-column prop="orderId" label="子订单号" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.orderId + ''"
                placement="bottom"
              >
                <div class="exceed">{{ row.orderId }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="orderCreateTime"
            label="下单时间"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.orderCreateTime + ''"
                placement="bottom"
              >
                <div class="exceed">{{ row.orderCreateTime }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="settlementObject"
            label="结算对象"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.settlementObject + ''"
                placement="bottom"
              >
                <div class="exceed">{{ row.settlementObject }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="commission" label="佣金（元）" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                :content="row.commission + ''"
                effect="dark"
                placement="bottom"
              >
                <div class="exceed">{{ row.commission }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="结算状态" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                :content="
                  row.status == 0
                    ? '未冻结'
                    : row.status == 1
                    ? '已冻结'
                    : '已提现'
                "
                effect="dark"
                placement="bottom"
              >
                <div class="exceed">
                  {{
                    row.status == 0
                      ? "未冻结"
                      : row.status == 1
                      ? "已冻结"
                      : "已提现"
                  }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column prop="goodsName" label="商品名称" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                :content="row.goodsName + ''"
                effect="dark"
                placement="bottom"
              >
                <div class="exceed">{{ row.goodsName }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="articleNumber" label="货号" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.articleNumber + ''"
                placement="bottom"
              >
                <div class="exceed">{{ row.articleNumber }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        background
        :current-page="currPage"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="footer">
      <div @click="goback">返回</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  data() {
    return {
      acticommList: {}, //明细信息
      WithdrawDetaList: {}, //列表信息
      pageSize: 10, //每页条数
      total: 1, // 总页数
      currPage: 1,
      fiApi: {
        id: this.$route.query.id,
        currPage: 1,
      },
    };
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
      "userType",
    ]),
  },

  created() {
    this.WithdrawDetail();
    this.acticommList = JSON.parse(sessionStorage.getItem("acticommList"));
  },
  mounted() {},
  methods: {
    ...mapActions(["getWithdrawDetailList", "postWithdrawList"]),
    goback() {
      history.go(-1);
    },
    // async getList() {
    //   let res = await this.postWithdrawList({
    //     businessId: this.$route.query.id,
    //   });
    //   res.content.list.map((item) => {
    //     if (item.id == this.$route.query.id) {
    //       this.acticommList = item;
    //       return
    //     }else{

    //     }
    //   });
    // },
    // 获取列表交易信息
    async WithdrawDetail() {
      if (this.$route.query.id) {
        let res = await this.getWithdrawDetailList(this.fiApi);
        if (res.code == "000000") {
          this.WithdrawDetaList = res.content;
          this.total = res.content.total;
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
          return;
        }
      }
    },
    async handleCurrentChange(e) {
      this.fiApi.currPage = e;
      this.WithdrawDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  // .el-input {
  //   width: 300px !important;
  // }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

.el-tabs__item.is-active {
  color: #06b7ae;
}

.el-tabs__item:hover {
  color: #06b7ae;
}

.el-tabs__active-bar {
  background-color: #06b7ae;
}

.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}

.el-input__inner {
  border-radius: 4px !important;
}

.el-range-separator {
  width: 20% !important;
}
</style>

<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .search {
      display: flex;
      .search-box1 {
        width: 250px;
        margin-right: 20px;
      }
      .search-btn {
        width: 80px;
        height: 40px;
        line-height: 40px;
        background-color: #06b7ae;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
::v-deep .el-select {
  width: 100%;
}
.table-box {
  margin-top: 20px;
}

.mess {
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
  color: #666;
  padding: 0 10px;
  > div {
    flex: 1;
    margin-right: 10px;
    > div {
      margin: 10px 0;
      display: flex;

      div {
        color: #222;
        line-height: 20px;
      }
      .mess-lable {
        white-space: nowrap;
        margin-left: 40px;
        color: #666;
      }
      .nomargin {
        margin-left: 0;
      }
    }
  }
}
.mess-title {
  font-size: 16px;
  font-weight: 600;
  padding-top: 13px;
}
.mess-title::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
  margin-bottom: -2px;
}
.title-bor {
  padding-top: 30px;
  border-top: 1px dashed rgba($color: #000000, $alpha: 0.3);
  margin-top: 15px;
}
.footer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  div {
    text-align: center;
    width: 60px;
    line-height: 32px;
    background-color: #06b7ae;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
